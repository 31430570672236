*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: Noto Sans, Tenor Sans, sans-serif;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: rgba(0, 0, 0, 0);
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[type="text"], [type="email"], [type="url"], [type="password"], [type="number"], [type="date"], [type="datetime-local"], [type="month"], [type="search"], [type="tel"], [type="time"], [type="week"], [multiple], textarea, select {
  -webkit-appearance: none;
  appearance: none;
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  background-color: #fff;
  border-width: 1px;
  border-color: #6b7280;
  border-radius: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

[type="text"]:focus, [type="email"]:focus, [type="url"]:focus, [type="password"]:focus, [type="number"]:focus, [type="date"]:focus, [type="datetime-local"]:focus, [type="month"]:focus, [type="search"]:focus, [type="tel"]:focus, [type="time"]:focus, [type="week"]:focus, [multiple]:focus, textarea:focus, select:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width) ) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #2563eb;
  outline: 2px solid rgba(0, 0, 0, 0);
}

input::placeholder, textarea::placeholder {
  color: #6b7280;
  opacity: 1;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-date-and-time-value {
  min-height: 1.5em;
}

::-webkit-datetime-edit, ::-webkit-datetime-edit-year-field, ::-webkit-datetime-edit-month-field, ::-webkit-datetime-edit-day-field, ::-webkit-datetime-edit-hour-field, ::-webkit-datetime-edit-minute-field, ::-webkit-datetime-edit-second-field, ::-webkit-datetime-edit-millisecond-field, ::-webkit-datetime-edit-meridiem-field {
  padding-top: 0;
  padding-bottom: 0;
}

select {
  color-adjust: exact;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right .5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
}

[multiple] {
  background-image: initial;
  background-position: initial;
  background-repeat: unset;
  background-size: initial;
  color-adjust: unset;
  padding-right: .75rem;
}

[type="checkbox"], [type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  color-adjust: exact;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  height: 1rem;
  width: 1rem;
  color: #2563eb;
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  background-color: #fff;
  background-origin: border-box;
  border-width: 1px;
  border-color: #6b7280;
  flex-shrink: 0;
  padding: 0;
  display: inline-block;
}

[type="checkbox"] {
  border-radius: 0;
}

[type="radio"] {
  border-radius: 100%;
}

[type="checkbox"]:focus, [type="radio"]:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width) ) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  outline: 2px solid rgba(0, 0, 0, 0);
}

[type="checkbox"]:checked, [type="radio"]:checked {
  background-color: currentColor;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: rgba(0, 0, 0, 0);
}

[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

[type="radio"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}

[type="checkbox"]:checked:hover, [type="checkbox"]:checked:focus, [type="radio"]:checked:hover, [type="radio"]:checked:focus {
  background-color: currentColor;
  border-color: rgba(0, 0, 0, 0);
}

[type="checkbox"]:indeterminate {
  background-color: currentColor;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: rgba(0, 0, 0, 0);
}

[type="checkbox"]:indeterminate:hover, [type="checkbox"]:indeterminate:focus {
  background-color: currentColor;
  border-color: rgba(0, 0, 0, 0);
}

[type="file"] {
  background: unset;
  border-color: inherit;
  font-size: unset;
  line-height: inherit;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

[type="file"]:focus {
  outline: 1px auto -webkit-focus-ring-color;
}

*, :before, :after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, .5);
  --tw-ring-offset-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-ring-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0);
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

::-webkit-backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, .5);
  --tw-ring-offset-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-ring-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0);
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, .5);
  --tw-ring-offset-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-ring-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0);
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mr-4 {
  margin-right: 1rem;
}

.ml-4 {
  margin-left: 1rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.flex {
  display: flex;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.h-12 {
  height: 3rem;
}

.h-full {
  height: 100%;
}

.h-\[90vh\] {
  height: 90vh;
}

.h-20 {
  height: 5rem;
}

.h-16 {
  height: 4rem;
}

.h-\[60vh\] {
  height: 60vh;
}

.h-\[v10\] {
  height: v10;
}

.h-screen {
  height: 100vh;
}

.min-h-full {
  min-height: 100%;
}

.w-full {
  width: 100%;
}

.w-\[40vh\] {
  width: 40vh;
}

.\!w-auto {
  width: auto !important;
}

.max-w-md {
  max-width: 28rem;
}

.max-w-full {
  max-width: 100%;
}

.max-w-\[v20\] {
  max-width: v20;
}

.max-w-screen-2xl {
  max-width: 1536px;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.25rem * var(--tw-space-x-reverse) );
  margin-left: calc(.25rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.rounded-xl {
  border-radius: .75rem;
}

.border-2 {
  border-width: 2px;
}

.border-black {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity) );
}

.bg-secondary-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(25 51 46 / var(--tw-bg-opacity) );
}

.bg-secondary-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(112 184 169 / var(--tw-bg-opacity) );
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity) );
}

.bg-blue-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(191 219 254 / var(--tw-bg-opacity) );
}

.bg-primary-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(47 68 106 / var(--tw-bg-opacity) );
}

.bg-brand-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(23 19 17 / var(--tw-bg-opacity) );
}

.fill-\[\#006a71\] {
  fill: #006a71;
}

.p-1 {
  padding: .25rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-2 {
  padding: .5rem;
}

.p-4 {
  padding: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pl-1 {
  padding-left: .25rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pl-5 {
  padding-left: 1.25rem;
}

.pl-6 {
  padding-left: 1.5rem;
}

.pt-11 {
  padding-top: 2.75rem;
}

.pr-4 {
  padding-right: 1rem;
}

.text-center {
  text-align: center;
}

.font-serif {
  font-family: Alice, serif;
}

.font-sans {
  font-family: Noto Sans, Tenor Sans, sans-serif;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.leading-normal {
  line-height: 1.5;
}

.tracking-wider {
  letter-spacing: .05em;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity) );
}

.text-gray-100 {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity) );
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity) );
}

.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity) );
}

.text-brand-50 {
  --tw-text-opacity: 1;
  color: rgb(89 72 66 / var(--tw-text-opacity) );
}

.text-primary-100 {
  --tw-text-opacity: 1;
  color: rgb(70 101 159 / var(--tw-text-opacity) );
}

.underline {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.shadow-button-hard {
  --tw-shadow: 2px 2px 0px 0px #000;
  --tw-shadow-colored: 2px 2px 0px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.shadow {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px -1px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.ring-white {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 255 255 / var(--tw-ring-opacity) );
}

.ring-opacity-60 {
  --tw-ring-opacity: .6;
}

.ring-offset-2 {
  --tw-ring-offset-width: 2px;
}

.ring-offset-primary-200 {
  --tw-ring-offset-color: #2f446a;
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.loading-title {
  --tw-text-opacity: 1;
  color: rgb(89 72 66 / var(--tw-text-opacity) );
  font-family: Alice, serif;
  font-size: 5vw;
  line-height: 1.5;
  position: absolute;
  top: 27vh;
  left: 36vw;
}

.superballs {
  --uib-size: 35vw;
  --uib-speed: 1.4s;
  --uib-color: black;
  height: var(--uib-size);
  width: var(--uib-size);
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 30vh;
  left: 33vw;
}

.superballs__dot {
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.superballs__dot:before {
  content: "";
  height: 30%;
  width: 30%;
  background-color: var(--uib-color);
  will-change: transform;
  border-radius: 50%;
  flex-shrink: 0;
}

.superballs__dot:nth-child(1) {
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.superballs__dot:nth-child(1):before {
  animation: orbit var(--uib-speed) linear calc(var(--uib-speed) * -.143) infinite;
}

.superballs__dot:nth-child(2) {
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.superballs__dot:nth-child(2):before {
  animation: orbit var(--uib-speed) linear calc(var(--uib-speed) / -2) infinite;
}

@keyframes orbit {
  0% {
    -ms-transform: translate(calc(var(--uib-size) * .5) ) scale(.73684);
    transform: translate(calc(var(--uib-size) * .5) ) scale(.73684);
    opacity: .65;
  }

  5% {
    -ms-transform: translate(calc(var(--uib-size) * .4) ) scale(.684208);
    transform: translate(calc(var(--uib-size) * .4) ) scale(.684208);
    opacity: .58;
  }

  10% {
    -ms-transform: translate(calc(var(--uib-size) * .3) ) scale(.631576);
    transform: translate(calc(var(--uib-size) * .3) ) scale(.631576);
    opacity: .51;
  }

  15% {
    -ms-transform: translate(calc(var(--uib-size) * .2) ) scale(.578944);
    transform: translate(calc(var(--uib-size) * .2) ) scale(.578944);
    opacity: .44;
  }

  20% {
    -ms-transform: translate(calc(var(--uib-size) * .1) ) scale(.526312);
    transform: translate(calc(var(--uib-size) * .1) ) scale(.526312);
    opacity: .37;
  }

  25% {
    opacity: .3;
    -ms-transform: translate(0%)scale(.47368);
    transform: translate(0%)scale(.47368);
  }

  30% {
    -ms-transform: translate(calc(var(--uib-size) * -.1) ) scale(.526312);
    transform: translate(calc(var(--uib-size) * -.1) ) scale(.526312);
    opacity: .37;
  }

  35% {
    -ms-transform: translate(calc(var(--uib-size) * -.2) ) scale(.578944);
    transform: translate(calc(var(--uib-size) * -.2) ) scale(.578944);
    opacity: .44;
  }

  40% {
    -ms-transform: translate(calc(var(--uib-size) * -.3) ) scale(.631576);
    transform: translate(calc(var(--uib-size) * -.3) ) scale(.631576);
    opacity: .51;
  }

  45% {
    -ms-transform: translate(calc(var(--uib-size) * -.4) ) scale(.684208);
    transform: translate(calc(var(--uib-size) * -.4) ) scale(.684208);
    opacity: .58;
  }

  50% {
    -ms-transform: translate(calc(var(--uib-size) * -.5) ) scale(.73684);
    transform: translate(calc(var(--uib-size) * -.5) ) scale(.73684);
    opacity: .65;
  }

  55% {
    -ms-transform: translate(calc(var(--uib-size) * -.4) ) scale(.789472);
    transform: translate(calc(var(--uib-size) * -.4) ) scale(.789472);
    opacity: .72;
  }

  60% {
    -ms-transform: translate(calc(var(--uib-size) * -.3) ) scale(.842104);
    transform: translate(calc(var(--uib-size) * -.3) ) scale(.842104);
    opacity: .79;
  }

  65% {
    -ms-transform: translate(calc(var(--uib-size) * -.2) ) scale(.894736);
    transform: translate(calc(var(--uib-size) * -.2) ) scale(.894736);
    opacity: .86;
  }

  70% {
    -ms-transform: translate(calc(var(--uib-size) * -.1) ) scale(.947368);
    transform: translate(calc(var(--uib-size) * -.1) ) scale(.947368);
    opacity: .93;
  }

  75% {
    opacity: 1;
    -ms-transform: translate(0%)scale(1);
    transform: translate(0%)scale(1);
  }

  80% {
    -ms-transform: translate(calc(var(--uib-size) * .1) ) scale(.947368);
    transform: translate(calc(var(--uib-size) * .1) ) scale(.947368);
    opacity: .93;
  }

  85% {
    -ms-transform: translate(calc(var(--uib-size) * .2) ) scale(.894736);
    transform: translate(calc(var(--uib-size) * .2) ) scale(.894736);
    opacity: .86;
  }

  90% {
    -ms-transform: translate(calc(var(--uib-size) * .3) ) scale(.842104);
    transform: translate(calc(var(--uib-size) * .3) ) scale(.842104);
    opacity: .79;
  }

  95% {
    -ms-transform: translate(calc(var(--uib-size) * .4) ) scale(.789472);
    transform: translate(calc(var(--uib-size) * .4) ) scale(.789472);
    opacity: .72;
  }

  100% {
    -ms-transform: translate(calc(var(--uib-size) * .5) ) scale(.73684);
    transform: translate(calc(var(--uib-size) * .5) ) scale(.73684);
    opacity: .65;
  }
}

.hover\:bg-white\/\[0\.12\]:hover {
  background-color: rgba(255, 255, 255, .12);
}

.hover\:bg-primary-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(70 101 159 / var(--tw-bg-opacity) );
}

.hover\:bg-secondary-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(41 82 73 / var(--tw-bg-opacity) );
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity) );
}

.hover\:underline:hover {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.hover\:shadow-button-hard-hover:hover {
  --tw-shadow: 4px 4px 0px 0px #000;
  --tw-shadow-colored: 4px 4px 0px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid rgba(0, 0, 0, 0);
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width) ) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 rgba(0, 0, 0, 0));
}

@media (prefers-color-scheme: dark) {
  .dark\:text-gray-100 {
    --tw-text-opacity: 1;
    color: rgb(243 244 246 / var(--tw-text-opacity) );
  }
}

@media (min-width: 640px) {
  .sm\:mt-0 {
    margin-top: 0;
  }

  .sm\:block {
    display: block;
  }

  .sm\:hidden {
    display: none;
  }

  .sm\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .sm\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .sm\:text-center {
    text-align: center;
  }
}

@media (min-width: 768px) {
  .md\:mr-6 {
    margin-right: 1.5rem;
  }

  .md\:flex {
    display: flex;
  }

  .md\:shrink-0 {
    flex-shrink: 0;
  }

  .md\:items-center {
    align-items: center;
  }

  .md\:justify-between {
    justify-content: space-between;
  }

  .md\:p-6 {
    padding: 1.5rem;
  }

  .md\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .md\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .md\:pt-4 {
    padding-top: 1rem;
  }

  .md\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .md\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

/*# sourceMappingURL=index.9e81a205.css.map */
